/**
 * Add event names (or name regex's) for any frontend events which should be sent to pendo
 */

const allowList = [
  'benefits_shopping_banner_shown',
  'company_setup_tasks_loaded',
  'company_setup_mark_section_submitted',
  'form_errors',
  'form_submit_validation_error',
  'form_submitted',
  'form_submit_error',
  'employee_terminated',
  'new_US_state_added_in_work_locations',
  'checkout_submission_success',
  'checkout_submission_error',
  // Mobile checkout events
  'demo_checkout_small_top_cta',
  'demo_checkout_more_cta',
  'trial_checkout_small_top_cta',
  'trial_checkout_more_cta',
  'trial_checkout_expired_dialog_cta',
];

function shouldTrackEvent(eventName) {
  return allowList.some(el => eventName.match(el));
}

module.exports = shouldTrackEvent;
