import { zen, sessionTimer, getEventLogger, prerequisiteRedirectAllowlist } from 'z-frontend-app-bootstrap';

// Export globals for ember to use
window.zenJs = zen;
window.zenJs.sessionTimer = sessionTimer;
window.zenJs.eventLogger = getEventLogger().uiEventLogger;
window.zenJs.prerequisiteRedirectAllowlist = prerequisiteRedirectAllowlist;

window.embeddedReactApps.boot = window.embeddedReactApps.boot || {};

if (window.embeddedReactApps.boot.waitForReady) {
  window.embeddedReactApps.boot.waitForReady.then(startBootApp);
} else {
  startBootApp();
}

function startBootApp() {
  const bootApp = window.embeddedReactApps.boot;
  bootApp.registeredReactMessages = bootApp.registeredReactMessages || {};
  const bootContainer = document.getElementById('react-boot-container') || document.getElementById('react-top-navbar');

  bootContainer.classList.remove('loading');

  if (window.ZENEFITS_MOBILE_INTEGRATION && window.ZENEFITS_MOBILE_INTEGRATION.isEmbeddedNativeView) {
    // Remove top nav bar preload container
    bootContainer.parentNode.removeChild(bootContainer);

    // Remove the element in Django template that has Zenefits logo in the middle
    const navigationContainerDjango = document.querySelector('.z-navigation-container-django');
    if (navigationContainerDjango) {
      navigationContainerDjango.parentNode.removeChild(navigationContainerDjango);
    } else {
      console.warn(`Can't find element with class "z-navigation-container-django"`);
    }

    // Also hide main-navigation component on Ember side.
  } else {
    bootApp.start(bootContainer).then(function() {
      // NOTE: we were doing this in Ember to hide django's spinner
      if (document.querySelector('.z-navigation-container')) {
        document.querySelector('.z-navigation-container').style.display = 'none';
      }
      if (document.querySelector('.z-djangoSpinner')) {
        document.querySelector('.z-djangoSpinner').style.display = 'none';
      }
      bootApp.isAppStarted = true;
    });
  }
}
