import { omitBy, isEqual } from 'lodash';
import { shouldTrackEvent } from 'z-frontend-pendo';
import core from './core';
import cookie from './cookie';
import isBrowser from './checkIfBrowser';
import { printSnowplowWarningsInDev } from './ui-event-logger-validator';

let resolve;

if (isBrowser) {
  resolve = window.Promise && window.Promise.resolve.bind(window.Promise);
} else {
  resolve = Promise.resolve.bind(Promise);
}

const uiEventLogger = new (function() {
  let ajaxFn;
  let _loggingCouldNotLog = false;

  let eventTypes = {
    PLAIN_EVENT: 'fe_event',
    ERROR_EVENT: 'fe_error',
    BATCH_EVENT: 'fe_batch',
  };

  let DATA_FIELDS = {
    employeeId: null,
    companyId: null,
  };

  let DEMO_COMPANY_ID = 48;

  let generateAnonymousId = function() {
    let uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      let r = (Math.random() * 16) | 0;
      let v = c == 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
    return uuid;
  };

  let hashCode = function(str) {
    let hash = 0;
    let char;
    if (str.length === 0) {
      return hash;
    }
    for (let i = 0; i < str.length; i++) {
      char = str.charCodeAt(i);
      hash = (hash << 5) - hash + char;
      hash &= hash; // Convert to 32bit integer
    }
    return hash;
  };

  /**
   * Used to log client side events.
   * @param {string|object} appInfo
   * @param {string} environment
   * @param {function} ajax
   * @param {object} clientMeta - meta information about the clientside. Ex. referrer, ip address.
   */
  let EventLogger = function(appInfo, environment, ajax, clientMeta, options = {}) {
    // No actual logging for dev env
    if (environment == 'development') {
      this.doRequest = function(data) {
        if (!__TEST__) {
          // console.log('Log request', data);
        }
        return resolve(data);
      };
    }

    if (typeof appInfo === 'string') {
      appInfo = {
        appName: appInfo,
      };
    }

    let aid;
    let dt;

    ajaxFn = ajax; // Module-level var, pre-bound function

    this.currentUserData = core.shallowCopy({}, DATA_FIELDS);
    this.currentRoute = '';
    this.currentRouteName = '';
    this.previousRouteName = '';
    this.targetRoute = '';
    this.timings = {
      lastLoad: new Date().getTime(),
    };
    this.anonymousId = '';
    this.userId = '';
    this.appName = appInfo.appName;
    this.appVersion = appInfo.appVersion;
    this.environment = environment;
    this.clientMeta = clientMeta || {};
    this.categorySuffix = '';
    this.transitionId = null;

    this.maxQueueSize = 10;

    this.debounce = options.debounce || _.debounce;
    (this.debouncedFlushBatchLogs = this.debounce(this.flushBatchLogs.bind(this), options.batchDebounceWait || 1000)),
      (this._batchLogQueue = []);
    this._lastLoggedError = {};
    this._lastLoggedXhrError = {};
    this._scheduledLogs = {};

    this.logFilters = options.logFilters;

    if (environment != 'production') {
      this.categorySuffix = `_${environment}`;
    }

    if (cookie) {
      aid = cookie.get('ajs_anonymous_id');
      if (!aid) {
        aid = generateAnonymousId();
        dt = new Date();
        // Expire in one year
        dt.setTime(dt.getTime() + 365 * 24 * 60 * 60 * 1000);
        cookie.set('ajs_anonymous_id', aid, {
          expires: dt,
          domain: '.zenefits.com',
        });
      }
      aid = aid.replace(/%22/g, '');
      this.anonymousId = aid;
    }
    if (isBrowser) {
      window.addEventListener(
        'beforeunload',
        function() {
          this.flush();
        }.bind(this),
      );
    }
  };

  function getResourcesDurations() {
    if (!window.performance.getEntriesByType) {
      return {};
    }

    let resources = window.performance.getEntriesByType('resource');

    return {
      scriptDurations: evaluauteScriptDurations(resources),
      slowResources: evaluauteSlowResources(resources),
    };
  }

  function evaluauteScriptDurations(resources) {
    const filePathRegex = /.+\/\/.+?\/(.+)-.+\.(\w+)/;
    return resources
      .filter(resource => resource.initiatorType === 'script')
      .reduce((durations, resource) => {
        // To make this easier to search key off the filename and remove hash
        const matches = resource.name.match(filePathRegex);
        if (!matches || !matches[1]) {
          return durations;
        }

        const normalizedName = `${matches[1]}.${matches[2]}`;
        durations[normalizedName] = { duration: resource.duration };
        return durations;
      }, {});
  }

  const initiatorTypesWhitelist = ['link', 'img', 'css', 'fetch', 'xmlhttprequest'];
  // Include any resources that took over 2s to load
  function evaluauteSlowResources(resources) {
    return resources
      .filter(resource => resource.duration > 2000 && initiatorTypesWhitelist.includes(resource.initiatorType))
      .map(resource => ({
        name: resource.name,
        duration: resource.duration,
        initiatorType: resource.initiatorType,
      }));
  }

  function omitUndefinedOrEqualProps(obj1, obj2) {
    return omitBy(obj1, (val, key) => val === undefined || isEqual(obj2[key], val));
  }

  EventLogger.prototype = {
    getLoggerUrl() {
      let url;
      if (__UILOGGER_SERVICE_BASEURL__) {
        url = __UILOGGER_SERVICE_BASEURL__;
      } else if (this.environment == 'test') {
        url = '/api/logging';
      } else if (window.location.hostname === 'alpha.zenefits.com') {
        url = 'https://uilogger-staging.zenefits.com/';
      } else {
        url = 'https://uilogger.zenefits.com/';
      }
      return url;
    },

    addClientMeta(clientMeta) {
      this.clientMeta = { ...this.clientMeta, ...clientMeta };
    },

    setAppInfo(appInfo = {}) {
      this.appName = appInfo.appName || this.appName;
      this.appVersion = appInfo.appVersion || this.appVersion;
    },

    setTransitionInfo(targetRoute) {
      this.targetRoute = targetRoute || '';
      this.transitionId = `${this.userId}_${generateAnonymousId()}`;
    },

    batchLog(eventName, eventData, eventCategory) {
      if (this.logFilters && this.logFilters.some(filter => !filter(eventName, eventData, eventCategory))) {
        return;
      }

      const { data, properties } = this._generateLogData(eventName, eventData, eventCategory);
      this._batchLogQueue.push({ ...data, properties });

      if (this._batchLogQueue.length >= this.maxQueueSize) {
        return this.flushBatchLogs();
      }

      this.debouncedFlushBatchLogs();
    },
    flushBatchLogs() {
      let result;
      if (this._batchLogQueue.length > 0) {
        const { properties, ...data } = this._generateBatchLogData();
        result = this._sendLog(eventTypes.BATCH_EVENT, data, properties);
      }
      this._batchLogQueue = [];
      return result;
    },
    _generateBatchLogData() {
      const { data, properties } = this._generateLogData(eventTypes.BATCH_EVENT, {});

      const batchEvents = this._batchLogQueue.map(batchLog => {
        const topLevelOverrides = omitUndefinedOrEqualProps(batchLog, data);
        const propertiesOverrides = omitUndefinedOrEqualProps(batchLog.properties, properties);

        const dedupedBatchLog = {
          ...topLevelOverrides,
          properties: propertiesOverrides,
        };

        // Also diff these top level properties
        ['context', 'privateLevelData', 'traits'].forEach(prop => {
          if (batchLog[prop]) {
            dedupedBatchLog[prop] = omitUndefinedOrEqualProps(batchLog[prop], data[prop]);
          }
        });

        return dedupedBatchLog;
      });

      return {
        ...data,
        properties: {
          batchEvents,
        },
      };
    },
    flush() {
      this.flushBatchLogs();

      Object.keys(this._scheduledLogs).forEach(key => {
        this._sendLog(
          this._scheduledLogs[key].data.event,
          this._scheduledLogs[key].data,
          this._scheduledLogs[key].properties,
        );
        clearTimeout(this._scheduledLogs[key].timeout);
        delete this._scheduledLogs[key];
      });
    },

    // Fire events on the window so we can pick them up in cypress
    _firePageViewEvents(eventData, eventProperties) {
      if (eventProperties.pageLoadTime) {
        window.dispatchEvent(
          new CustomEvent('hardNavigationTime', {
            detail: {
              appName: eventData.appName,
              hardNavigationTime: eventProperties.pageLoadTime,
              page: eventData.context.currentRoute,
            },
          }),
        );
      } else if (eventProperties.loadTime) {
        window.dispatchEvent(
          new CustomEvent('softNavigationTime', {
            detail: {
              appName: eventData.appName,
              softNavigationTime: eventProperties.loadTime,
              page: eventData.context.currentRoute,
            },
          }),
        );
      }
    },

    _generateLogData(eventName, eventData = {}, eventCategory) {
      const { appInfo, clientMeta, fromReact, ...eventProperties } = eventData;

      let category = (eventCategory || eventTypes.PLAIN_EVENT) + this.categorySuffix;
      let meta = clientMeta || this.clientMeta;
      let { userId } = this;
      let { anonymousId } = this;
      let data;
      let properties;
      let dt = new Date();
      let { timings } = this;
      let performanceTiming;
      let styleVersion;
      const isEmbeddedNativeView = !!(
        window.ZENEFITS_MOBILE_INTEGRATION && window.ZENEFITS_MOBILE_INTEGRATION.isEmbeddedNativeView
      );

      data = {
        event: eventName,
        category,
        timestamp: this.getTimestamp(dt),
        appName: (appInfo && appInfo.appName) || this.appName,
        environment: this.environment,
      };
      if (userId) {
        data.userId = userId;
      } else {
        data.anonymousId = anonymousId;
      }

      data.traits = core.shallowCopy(
        {
          anonymousId,
        },
        this.currentUserData,
      );
      data.privateData = this.privateData;

      data.context = {
        fromReact,
        ip: meta.remoteAddr,
        referer: meta.httpReferer,
        currentRouteName: eventProperties.currentRouteName || this.currentRouteName,
        targetRoute: this.targetRoute,
        sessionId: meta.sessionId && Math.abs(hashCode(meta.sessionId)).toString(),
        isEmbeddedNativeView,
      };

      if (meta.nativePlatform) {
        data.context.nativePlatform = meta.nativePlatform;
        data.context.nativePlatformVersion = meta.nativePlatformVersion;
      }

      if (isBrowser) {
        data.context.userAgent = window.navigator.userAgent;
        data.context.screenData = core.safeToJSON(window.screen);
        data.context.currentRoute = window.document.location.hash.substr(1);
        data.context.href = window.document.location.href;
      }

      properties = {
        loggerVersion: core.version,
        appVersion: (appInfo && appInfo.appVersion) || this.appVersion,
        sendToPendo: shouldTrackEvent(eventName),
      };

      if (eventProperties) {
        ['name', 'revenue', 'currency', 'value'].forEach(function(key) {
          if (eventProperties[key]) {
            throw new Error(`"${key}" is a reserved word in event properties.`);
          }
        });
        properties = core.shallowCopy(properties, eventProperties);
      }

      if (eventName == 'pageview') {
        properties.previousRoute = this.currentRoute;
        properties.previousRouteName = this.currentRouteName;
        this.currentRoute = properties.currentRoute;
        this.currentRouteName = properties.currentRouteName;

        dt = dt.getTime();
        // If very first route transition, log page load-time data
        if (!properties.previousRoute) {
          performanceTiming = window.performance && window.performance.timing;
          if (performanceTiming) {
            performanceTiming = core.safeToJSON(performanceTiming);
            // Page load time is terminology we used in ember to represent hard nav time.
            // eslint-disable-next-line compat/compat
            properties.pageLoadTime = fromReact ? properties.hardNavigationTime : performance.now();
            performanceTiming.routeTransitionEnd = dt;
            properties.timing = performanceTiming;

            const resourcesDurations = getResourcesDurations();
            properties.scriptTimings = resourcesDurations.scriptDurations;
            properties.slowResources = resourcesDurations.slowResources;
          }
        }
        // Route-transition timings
        if (timings.routeTransitionStart) {
          properties.timeInPreviousRoute = timings.routeTransitionStart - timings.lastLoad;
          properties.loadTime = fromReact ? properties.softNavigationTime : dt - timings.routeTransitionStart;
        } else {
          properties.loadTime = fromReact ? properties.softNavigationTime : dt - timings.lastLoad;
        }

        this.targetRoute = '';
        timings.lastLoad = dt;
        // Old vs. new style
        if (typeof window.$ == 'function') {
          if (
            ['v1', 'v2'].some(function(v) {
              styleVersion = v;
              return window.$(document.body).hasClass(`${v}-zenefits`);
            })
          ) {
            properties.styleVersion = styleVersion;
          } else {
            properties.styleVersion = 'unknown';
          }
        }

        data.context.connection = core.safeToJSON(window.navigator.connection);

        /**
         * Info about embedded React apps (not full page React) on current page.
         */
        const reactAppsInUse = [];
        if (window.embeddedReactApps) {
          Object.entries(window.embeddedReactApps).forEach(([reactAppName, reactApp]) => {
            if (reactApp && reactApp.isInUse) {
              reactAppsInUse.push(reactAppName);
            }
          });
        }
        properties.embeddedReactApps = reactAppsInUse;
      }

      if (eventName === 'pageview') {
        this._firePageViewEvents(data, properties);
      }

      return { data, properties };
    },

    _sendLog(eventName, data, properties) {
      let propertiesString;
      let truncatedEventData;

      try {
        propertiesString = JSON.stringify(properties);
        if (propertiesString.length > core.EVENT_LOGGER_MAX_NUM_CHARACTERS) {
          truncatedEventData = {
            truncatedEventData: propertiesString.substr(0, core.EVENT_LOGGER_MAX_NUM_CHARACTERS - 1024),
          };
          return this.logCouldNotLogEvent(
            eventName,
            '`properties` property exceeds max number of characters',
            truncatedEventData,
          );
        } else {
          data.properties = properties;
        }
      } catch (error) {
        return this.logCouldNotLogEvent(eventName, 'fails to stringify event data');
      }
      _loggingCouldNotLog = false;

      logSegmentPageViews(data);
      printSnowplowWarningsInDev(data);
      return this.doRequest(data);
    },

    addEventProperties(logId, properties) {
      if (this._scheduledLogs[logId]) {
        this._scheduledLogs[logId].properties = { ...this._scheduledLogs[logId].properties, ...properties };
      } else {
        this._scheduledLogs[logId] = { properties, data: {} };
      }
    },

    addLogFilter(logFilter) {
      this.logFilters = this.logFilters || [];
      this.logFilters.push(logFilter);
    },

    // Schedule a log to be sent in some timeout. This allows additional properties to be added from other data sources
    scheduleLog(eventName, eventData = {}, eventCategory, logId, timeout = 1000) {
      if (this.logFilters && this.logFilters.some(filter => !filter(eventName, eventData, eventCategory))) {
        return;
      }

      const { data, properties } = this._generateLogData(eventName, eventData, eventCategory);
      if (this._scheduledLogs[logId]) {
        this._scheduledLogs[logId].data = { ...this._scheduledLogs[logId].data, ...data };
        this._scheduledLogs[logId].properties = { ...this._scheduledLogs[logId].properties, ...properties };
      } else {
        this._scheduledLogs[logId] = { data, properties };
      }

      if (this._scheduledLogs[logId].timeout) {
        clearTimeout(this._scheduledLogs[logId].timeout);
        this._scheduledLogs[logId].timeout = null;
      }

      this._scheduledLogs[logId].timeout = setTimeout(() => {
        this._sendLog(eventName, this._scheduledLogs[logId].data, this._scheduledLogs[logId].properties);
        delete this._scheduledLogs[logId];
      }, timeout);
    },

    log(eventName, eventData = {}, eventCategory) {
      if (this.logFilters && this.logFilters.some(filter => !filter(eventName, eventData, eventCategory))) {
        return;
      }

      const { data, properties } = this._generateLogData(eventName, eventData, eventCategory);
      return this._sendLog(eventName, data, properties);
    },

    logError(obj, eventData = {}) {
      let err;
      if (!obj) {
        return this.logCouldNotLogEvent(null, 'no arg passed');
      }

      if (obj._settings) {
        return this.logXhrError(obj, eventData);
      } else {
        // Sometimes people still throw strings
        if (typeof obj == 'string') {
          err = new Error(obj);
        } else {
          err = obj;
        }
        // Assume if people have created custom Error classes, they've done it right
        if (err instanceof Error) {
          return this.logRuntimeError(err, eventData);
        } else {
          return this.logCouldNotLogEvent(null, 'not an Error object', { data: err });
        }
      }
    },

    logCouldNotLogEvent(originalEventName, reason, data) {
      if (_loggingCouldNotLog) {
        console.error('something happened while logging this could_not_log event');
        _loggingCouldNotLog = false;
        return;
      }
      _loggingCouldNotLog = true;
      return this.log('could_not_log', this._generatePayloadForCouldNotLogEvent(originalEventName, reason, data));
    },

    _generatePayloadForCouldNotLogEvent(originalEventName, reason, data) {
      let eventObject = {
        originalEvent: originalEventName,
        reason,
      };
      if (data) {
        eventObject = core.shallowCopy(eventObject, data);
      }
      return eventObject;
    },

    logRuntimeError(err, eventData = {}) {
      let errType;
      let stack;
      let data;
      let last = this._lastLoggedError;
      // Don't send spam error reports for same error, same user
      if (err.message == last.message && err.stack == last.stack) {
        return;
      }
      this._lastLoggedError = err;
      // Will generally be in the form of 'TypeError: Something bad happened'
      errType = err.toString().split(':')[0];
      stack = err.stack || '';
      data = {
        message: err.message,
        stack,
        ...eventData,
      };
      return this.log(errType, data, eventTypes.ERROR_EVENT);
    },

    logXhrError(xhr, eventData = {}) {
      let data;
      let responseText;
      let request;
      let { url } = xhr._settings;
      let { companyId } = this.currentUserData; // Should always have this
      let last = this._lastLoggedXhrError;

      // No infinite looping if something goes wrong with the log request
      if (url == this.getLoggerUrl()) {
        return;
      }
      // Don't spam Segment with errors from YourPeopleDemo
      if (companyId && companyId == DEMO_COMPANY_ID) {
        return;
      }
      // Ignore fucking Sppof
      if (/\/api\/spoof/.test(url)) {
        return;
      }
      // Don't send spam error reports for same error, same user
      if (
        url == (last._settings && last._settings.url) &&
        xhr.status == last.status &&
        xhr.statusText == last.statusText
      ) {
        return;
      }
      this._lastLoggedXhrError = xhr;

      responseText = xhr.responseText ? xhr.responseText.substr(0, 1064) : '';
      request = core.shallowCopy({}, xhr._settings);
      delete request.data;
      data = {
        request,
        response: {
          statusCode: xhr.status,
          statusText: xhr.statusText,
          headers: xhr.getAllResponseHeaders(),
          responseText,
        },
        ...eventData,
      };
      return this.log('XhrError', data, eventTypes.ERROR_EVENT);
    },
    doRequestBeacon(data) {
      window.navigator.sendBeacon(this.getLoggerUrl(), JSON.stringify(data));
    },
    doRequest(data) {
      let self = this;

      if (window.navigator && window.navigator.sendBeacon) {
        return this.doRequestBeacon(data);
      }
      return ajaxFn({
        url: this.getLoggerUrl(),
        type: 'POST',
        data: JSON.stringify(data),
        contentType: 'application/json',
        beforeSend(jqXHR) {
          jqXHR.setRequestHeader('X-Env', self.environment);
        },
      });
    },
    setCurrentUserData(data) {
      let userData = this.currentUserData;
      core.shallowCopy(userData, data);
      this.userId = data.userId;
    },
    setPrivateData(privateData) {
      this.privateData = { ...privateData };
    },
    getTimestamp(dt) {
      if (typeof dt.toISOString == 'function') {
        return dt.toISOString();
      }
      return dt.toString();
    },
  };

  this.createLoggerInitializer = function(config, clientMeta, Ember) {
    let loggerInstance;
    let ajaxFn;
    let { environment } = config;
    let isNode = new Function('try {return this===global;}catch(e){return false;}')();
    let isWq = config.modulePrefix == 'wq-app';
    let initializer;
    let appInfo = {
      appName: config.modulePrefix,
      appVersion: config.APP && config.APP.version,
    };
    ajaxFn = Ember.$.ajax;

    loggerInstance = new EventLogger(appInfo, environment, ajaxFn, clientMeta || {});

    // Globalize the logger singleton
    zen.eventLogger = loggerInstance;

    initializer = {
      name: 'eventLogger',
      initialize(container, application) {
        application.register('service:event-logger', loggerInstance, { instantiate: false });
        application.inject('route', 'eventLogger', 'service:event-logger');
        application.inject('controller', 'eventLogger', 'service:event-logger');
        application.inject('component', 'eventLogger', 'service:event-logger');
        // Hack to get our analytics service initialized
        // Breaks the Ember Level tests -- don't run under Node
        // TODO: find out why
        if (!(isNode || isWq)) {
          application.inject('route', 'analytics', 'service:analytics');
        }
      },
    };
    if (!isWq) {
      initializer.after = 'routing-service';
    }
    return initializer;
  };

  this.EventLogger = EventLogger;
})();

/**
 * Adding the user id allows us to use computed traits to represent number of users in a account which viewed a set of pages
 */
function getUserIdForPageView() {
  try {
    const userId = window.analytics.user().id();
    if (userId) {
      return userId;
    }
    const traits = window.analytics.user().traits();
    return traits.email;
  } catch (e) {
    return null;
  }
}

function logSegmentPageViews(data) {
  if (data.event !== 'pageview') return;

  const properties = {
    appName: data.appName,
    path: `${window.location.pathname}${window.location.hash}`,
    url: data.properties.url,
    referer: data.properties.previousRoute,
    currentRoute: data.properties.currentRoute,
    previousRoute: data.properties.previousRoute,
    fromReact: data.context.fromReact,
    isEmbeddedNativeView: data.context.isEmbeddedNativeView,
    userIdOrEmail: getUserIdForPageView(),
  };
  // NOTE: considered instead doing this from the logger service, however we would be missing context added by the lib
  window.analytics?.page(properties);
}

export default uiEventLogger;
