import { setContext } from 'apollo-link-context';

import { getEventLogger } from './event-logger';
import { generateUUID } from './uuid';

type Context = {
  headers: {
    [key: string]: string;
  };
  limitedBatching: boolean;
};

type UserContext = {
  userId?: string | null;
  employeeId?: string | null;
  companyId?: string | null;
};

// We can update this later ex: add userId when we have it
const context: Context = { headers: {}, limitedBatching: false };

export default setContext((request, prevContext) => ({
  ...prevContext,
  ...context,
  headers: {
    'X-REQUEST-ID': generateUUID(),
    'X-TRANSITION-ID': getEventLogger().transitionId,
    ...prevContext.headers,
    ...context.headers,
  },
}));

export function setUserContext(userContext: UserContext) {
  context.headers = {
    ...context.headers,
    'X-USER-ID': userContext.userId || '',
    'X-EMPLOYEE-ID': userContext.employeeId || '',
    'X-COMPANY-ID': userContext.companyId || '',
  };
}

export function setBatchingContext(limitedBatching: boolean) {
  context.limitedBatching = limitedBatching;
}
