import { useEffect, useState } from 'react';
import throttle from 'lodash/throttle';

// @ts-ignore
import { cookie, SessionTimer } from 'z-frontend-zen-js';

import fetchWrapper from './fetchWrapper';
import getAjaxAdapter from './utils/get-ajax-adapter';
import initMobileIntegration from './app-init/initMobileIntegration';

const ajaxAdapter = getAjaxAdapter(fetchWrapper);

function mockAjaxTokenCookie() {
  if (!cookie.get('ajaxtoken')) {
    cookie.set('ajaxtoken', 'dummy');
  }
}

if (__MOCK_MODE__) {
  mockAjaxTokenCookie();
}

declare global {
  interface Window {
    ZENEFITS_MOBILE_INTEGRATION: any;
    zen: any;
  }
}

window.zen = window.zen || {};

// NOTE: we initialize mobile here because we don't want to block on queries
// if we ever need to pass data during initialization, break it into a two part initialization
// so we can create the event emitter as early and be able to subscribe to events
initMobileIntegration();

// TODO: create specific wrapper instead of exposing the session timer.
const sessionTimer = new SessionTimer({ throttle, ajaxFn: ajaxAdapter });
window.ZENEFITS_MOBILE_INTEGRATION.sessionTimer = sessionTimer;

export enum UserAuthenticationState {
  unknown,
  anonymous,
  authenticated,
}
export function useUserAuthenticationState() {
  // We assume they have to be authenticated when we don't allow anonymous users
  const initialState = sessionTimer.allowAnonymousUsers
    ? UserAuthenticationState.unknown
    : UserAuthenticationState.authenticated;
  const [isAnonymous, setIsAnonymous] = useState<UserAuthenticationState>(initialState);
  useEffect(() => {
    if (isAnonymous === UserAuthenticationState.unknown) {
      sessionTimer
        .getInitialSessionCheckPromise()
        .then((sessionCheck: any) =>
          setIsAnonymous(
            sessionCheck.loggedIn ? UserAuthenticationState.authenticated : UserAuthenticationState.anonymous,
          ),
        );
    }
  }, []);
  return isAnonymous;
}

export default window.ZENEFITS_MOBILE_INTEGRATION.sessionTimer;
