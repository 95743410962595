export type RedirectAllowlistEntryType = {
  destination: string;
  allowedRoutesAndUrls: AllowedRoutesAndUrls[];
};

export type AllowedRoutesAndUrls = {
  emberRoute?: string;
  path: string;
};

/**
 * Partially migrated from yourPeople3/client-app/boot/utils/prerequisite-redirect-whitelist.js
 * New redirects should be only added to the React prerequisite redirect logic.
 * DO NOT add new prerequisite redirect group in Ember and React
 * Ember allowlisted redirects are located here: client-app/boot/utils/prerequisite-redirect-whitelist.js
 * */
export const prerequisiteRedirectAllowlist: { [key: string]: RedirectAllowlistEntryType } = {
  // Doc Archive
  'bundle-expired': {
    destination: '/dashboard/#/',
    allowedRoutesAndUrls: [
      { emberRoute: 'plaidVerification', path: '/plaid-verification' },
      { emberRoute: 'companyProfile.billingInformation', path: '/company-profile/billing-information' },
      { emberRoute: 'companyProfile.planInformation', path: '/company-profile/plan-information' },
      { emberRoute: 'companyProfile.renewal', path: '/company-profile/renewal' },
      { emberRoute: 'companyProfile.security-settings', path: '/company-profile/security-settings' },
      { emberRoute: 'billing.choosePlan', path: '/public-app/#/billing' },
      { path: '/app/support-flow/' },
    ],
  },
  'delinquent-customer-billing-admins': {
    destination: '/dashboard/#/',
    allowedRoutesAndUrls: [
      { emberRoute: 'plaidVerification', path: '/plaid-verification' },
      { emberRoute: 'companyProfile.billingInformation', path: '/company-profile/billing-information' },
      { emberRoute: 'companyProfile.planInformation', path: '/company-profile/plan-information' },
      { emberRoute: 'companyProfile.renewal', path: '/company-profile/renewal' },
      { emberRoute: 'companyProfile.security-settings', path: '/company-profile/security-settings' },
      { emberRoute: 'billing.choosePlan', path: '/public-app/#/billing' },
      { path: '/app/support-flow/' },
    ],
  },
  'delinquent-customer-non-billing-admins': {
    destination: '/dashboard/#/',
    allowedRoutesAndUrls: [{ path: '/app/support-flow/' }],
  },
  'company-setup-tasks': {
    destination: '/app/company-setup/#/tasks_overview',
    allowedRoutesAndUrls: [
      { emberRoute: 'payroll.settings.onboardingTaxes', path: '/payroll/settings/onboarding-taxes' },
      { emberRoute: 'companyProfile.billingInformation', path: '/company-profile/billing-information' },
      { emberRoute: 'companyProfile.planInformation', path: '/company-profile/plan-information' },
      { emberRoute: 'companyProfile.renewal', path: '/company-profile/renewal' },
      { emberRoute: 'companyProfile.security-settings', path: '/company-profile/security-settings' },
      { emberRoute: 'companyProfile.admins', path: '/company-profile/admins' },
      { emberRoute: 'rolesPermissions.addAdmin', path: '/roles-permissions/add-admin' },
      { path: '/app/company-setup/' },
      { path: '/app/datagrid-flow/' },
      { path: '/app/support-flow/' },
      { path: '/app/documents/' },
      { path: '/app/checkout/' },
      { path: '/app/company-hub/' },
      { path: '/app/cancellation/' },
    ],
  },
  // Hub data wizard for 1-10 company onboarding
  'company-setup.v1': {
    destination: 'hubDataWizard',
    allowedRoutesAndUrls: [{ emberRoute: 'hubDataWizard', path: '/core-information' }, { path: '/app/support-flow/' }],
  },
  // Lock EE dashboard until company setup is complete
  'company-setup-lock-dashboard': {
    destination: 'registration-complete',
    allowedRoutesAndUrls: [{ emberRoute: 'registration-complete', path: '/registration-complete' }],
  },
  'employee-onboarding-newHireFlow': {
    destination: 'employee.onboarding-v2.start',
    allowedRoutesAndUrls: [
      { emberRoute: 'employee.onboarding-v2', path: '/employee/onboarding/v2' },
      { emberRoute: 'employee.personalinfo', path: '/employee/personalinfo' },
      { emberRoute: 'employeeProfile', path: '/employee-profile' },
      { path: '/app/support-flow/' },
    ],
  },
  // Force sign up for agreeing to Terms of Use (TOU) agreements
  'tou-agreement-force': {
    destination: '/app/hr-employee/#/agreements/tou',
    allowedRoutesAndUrls: [{ path: '/app/support-flow/' }],
  },
  'trial-expiration-react-checkout': {
    destination: '/app/checkout/#/choose-plan',
    allowedRoutesAndUrls: [
      { emberRoute: 'plaidVerification', path: '/plaid-verification' },
      { path: '/app/support-flow/' },
      { path: '/app/checkout/' },
      { emberRoute: 'billing', path: '/dashboard/#/billing' },
    ],
  },
  // MFA force redirect
  'two-factor-prerequisite-redirect': {
    destination: '/dashboard/#/2fa/setup/select-method',
    allowedRoutesAndUrls: [
      { emberRoute: '2fa.setup.select-method', path: '/2fa/setup/select-method' },
      { emberRoute: '2fa.setup.phone', path: '2fa/setup/phone' },
      { emberRoute: '2fa.setup.app', path: '2fa/setup/app' },
    ],
  },
  /** Unit Test Redirect */
  'test-group': {
    destination: 'registration-complete',
    allowedRoutesAndUrls: [
      { emberRoute: 'companyProfile.billingInformation', path: '/company-profile/billing-information' },
      { path: 'registration-complete' },
      { path: '/app/support-flow/' },
    ],
  },
  'test-group-2': {
    destination: 'billing.choosePlan',
    allowedRoutesAndUrls: [
      { emberRoute: 'plaidVerification', path: '/plaid-verification' },
      { emberRoute: 'companyProfile.billingInformation', path: '/company-profile/billing-information' },
      { emberRoute: 'billing', path: '/public-app/#/billing' },
      { path: '/dashboard/#/billing' },
      { path: '/app/support-flow/' },
    ],
  },
};
