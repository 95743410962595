import { ApolloClient } from 'apollo-client';

let apolloClient: ApolloClient<any> | null;

declare global {
  interface Window {
    apolloClient: ApolloClient<any>;
    Cypress: any;
  }
}

export function getApollo() {
  if (!apolloClient) {
    throw new Error('apollo client has not been set yet');
  }
  return apolloClient;
}

export function getApolloIfExists() {
  return apolloClient;
}

export function resetApolloClient() {
  apolloClient = null;
}

export function setApolloClient(client: ApolloClient<any>) {
  if (apolloClient) {
    throw new Error('apollo client has been set already');
  }
  if (window.Cypress) {
    window.apolloClient = client;
  }

  apolloClient = client;
}

export function resetApolloClientForTesting(client: ApolloClient<any>) {
  apolloClient = null;
  setApolloClient(client);
}
