import cookie from './cookie';

const SESSION_ACTIVITY_EVENTS = ['mousemove', 'scroll', 'keyup', 'click'];
const SESSION_TIMER_BUFFER = 0.5;
const MAX_ACTIVITY_POLLING_INTERVAL = 10;
const MAX_SESSION_TEST_INTERVAL = 900;
let sessionTimer;

class SessionTimer {
  constructor(options) {
    this.sessionWarningInterval = options.sessionWarningInterval || 30;
    this.ajaxFn = options.ajaxFn;
    this.throttle = options.throttle || _.throttle;
    this.isAboutToExpire = false;
    this._events = {};
    this.started = false;
    this.allowAnonymousUsers = options.allowAnonymousUsers || false;
    this.isAnonymousMode = false;
    this.initialSessionCheckPromise = null;
  }

  async start() {
    if (!this.started) {
      const { loggedIn } = await this.getInitialSessionCheckPromise();
      if (!loggedIn) {
        if (this.allowAnonymousUsers) {
          this.isAnonymousMode = true;
          if (!cookie.get('csrftoken')) {
            return this.redirectToAnonymousAppRedirect();
          }
        } else {
          return this.redirectToLogin();
        }
      }

      if (!this.isAnonymousMode) {
        setTimeout(() => this._testSessionIsStillValid());
        // Subscribe to all events, but don't ping more too often
        const throttledExtendSession = this.throttle(
          this._extendSession.bind(this),
          MAX_ACTIVITY_POLLING_INTERVAL * 1000,
        );

        for (let i = 0; i < SESSION_ACTIVITY_EVENTS.length; i++) {
          window.addEventListener(SESSION_ACTIVITY_EVENTS[i], throttledExtendSession, false);
        }
      }
      this.started = true;
    }
  }

  getInitialSessionCheckPromise() {
    if (!this.initialSessionCheckPromise) {
      this.initialSessionCheckPromise = this._initialSessionCheck();
    }

    return this.initialSessionCheckPromise;
  }

  _initialSessionCheck() {
    return new Promise((resolve, reject) => {
      // Do a static check
      if (!cookie.get('ajaxtoken')) {
        return resolve({ loggedIn: false });
      }

      // Override the default redirect error callback if we allow anonymous users
      const errorCallback = error => {
        if (error.status === 403) {
          resolve({ loggedIn: false });
        } else {
          reject(error);
        }
      };
      this._testSessionIsStillValid(() => resolve({ loggedIn: true }), errorCallback);
    });
  }

  trigger(eventName) {
    if (this._events[eventName]) {
      this._events[eventName].forEach(cb => cb());
    }
  }

  on(eventName, callbackFn) {
    this._events[eventName] = this._events[eventName] || [];
    this._events[eventName].push(callbackFn.bind(this));
  }
  /** *
   * This is used by the native clients
   * Calling this, will fir an ajax call to the session handoff URL including a token
   * and will refresh the ajaxToken and session if needed
   */
  callWebSessionHandoff(sessionHandoffUrl) {
    this.ajaxFn({
      global: false,
      url: sessionHandoffUrl,
      success: data => {
        this.isAboutToExpire = false;
        this._testSessionIsStillValid();
      },
      error: error => this._redirectOrThrow(error),
    });
  }

  _sessionTestCallback = data => {
    let remaining = parseInt(data);
    if (!this.isAboutToExpire && remaining <= this.sessionWarningInterval) {
      this.isAboutToExpire = true;
      this.trigger('willExpire');
    }

    this._startSessionTimer(remaining);
  };

  _testSessionIsStillValid(
    sucessCallback = this._sessionTestCallback,
    errorCallback = error => this._redirectOrThrow(error),
  ) {
    this.ajaxFn({
      global: false,
      url: '/session_test',
      success: sucessCallback,
      error: errorCallback,
    });
  }

  _startSessionTimer(remaining) {
    let timeout;
    if (remaining < this.sessionWarningInterval) {
      timeout = 0.5 * remaining;
    } else {
      timeout = remaining - this.sessionWarningInterval + SESSION_TIMER_BUFFER;
    }

    if (sessionTimer) {
      clearTimeout(sessionTimer);
    }

    timeout = Math.max(Math.min(timeout, MAX_SESSION_TEST_INTERVAL), MAX_ACTIVITY_POLLING_INTERVAL);
    sessionTimer = setTimeout(() => this._testSessionIsStillValid(), timeout * 1000);
  }

  _extendSession() {
    if (this.isAnonymousMode) {
      return;
    }
    let self = this;
    this.ajaxFn({
      global: false,
      url: '/session_ping',
      success: data => {
        self.isAboutToExpire = false;
        self.trigger('sessionExtended');
        self._startSessionTimer(parseInt(data));
      },
      error: error => self._redirectOrThrow(error),
    });
  }

  _isRunningInEmbeddedNativeView() {
    return window.ZENEFITS_MOBILE_INTEGRATION && window.ZENEFITS_MOBILE_INTEGRATION.isEmbeddedNativeView;
  }

  _redirectOrThrow = error => {
    if (error.status === 403) {
      this.trigger('expired');
      if (this._isRunningInEmbeddedNativeView()) {
        // TODO:
        // showLoadingIndicator
        return;
      }
      this.redirectToLogin();
    } else {
      throw error;
    }
  };

  _getRedirectParams() {
    const search = window.location.search || '';
    const hash = window.location.hash || '';
    const redirectUrl = `${location.pathname || ''}${search}${hash}`;
    return redirectUrl ? `?next=${encodeURIComponent(redirectUrl)}` : '';
  }

  redirectToLogin() {
    window.location.replace(`/accounts/login/${this._getRedirectParams()}`);
  }

  redirectToAnonymousAppRedirect() {
    window.location.replace(`/toAnonymousAppRedirect/${this._getRedirectParams()}`);
  }

  _redirectIfNoAuthCookie() {
    if (!cookie.get('ajaxtoken')) {
      this.redirectToLogin();
    }
  }
}

export default SessionTimer;
