// @ts-ignore
import { debounce } from 'lodash';

import 'z-frontend-global-types';

// @ts-ignore
import { uiEventLogger } from 'z-frontend-zen-js';
import { FrontendAll } from 'z-frontend-snowplow';

import getAjaxAdapter from './utils/get-ajax-adapter';
import getLoggerFetchNative from './getLoggerFetchNative';

const ajaxAdapter = getAjaxAdapter(__NATIVE__ ? getLoggerFetchNative() : undefined);

const { EventLogger: UIEventLogger } = uiEventLogger;

const GQL_TIMING_EVENT = 'gql_timing';

type CommonTimingProps = { limitedGqlBatching: boolean };

let commonTimingProps = { limitedGqlBatching: false };

export type logFn<T extends string | 'pageview'> = (
  eventName: T,
  eventData?: T extends 'pageview' ? Object : FrontendAll['properties'],
) => void;

export function setCommonTimingProps(props: CommonTimingProps) {
  commonTimingProps = props;
}

export function getCommonTimingProps() {
  return commonTimingProps;
}

declare global {
  interface Window {
    embeddedReactApps: any;
  }
}

export const getAppInfo = () => {
  let appVersion;
  if (__NATIVE__) {
    appVersion = __APP_VERSION__;
  } else if (window && window.document) {
    const versionMetaElement = window.document.getElementById('appInfo/version');
    if (versionMetaElement) {
      appVersion = versionMetaElement.getAttribute('content');
    }
  }

  return {
    // NOTE: we get the appVersion from meta to avoid invalidating the cache
    appVersion,
    appName: __APP_NAME__,
  };
};

type UIEventLogger = {
  anonymousId: string;
  clientMeta: { [key: string]: any };
  log: (eventName: string, data?: any) => void;
  batchLog: (eventName: string, data?: any) => void;
  logXhrError: (error: Object, eventData: { [key: string]: any }) => void;
  logError: (error: Error, eventData: { [key: string]: any }) => void;
  setTransitionInfo: (route: string) => void;
  transitionId: string | null;
  setCurrentUserData: (params: { [key: string]: any }) => void;
  setPrivateData: (params: { [key: string]: any }) => void;
  timings: { routeTransitionStart?: number; lastLoad: number };
  scheduleLog: (
    eventName: string,
    eventData: any,
    eventCategory?: string | null,
    logId?: string,
    timeout?: number,
  ) => void;
  addEventProperties: (logId: string, properties: any) => void;
  addClientMeta: (clientMeta: { [key: string]: any }) => void;
  addLogFilter: (filter: LogFilter) => void;
};

type EventLogger = Omit<UIEventLogger, 'clientMeta' | 'timings' | 'logError' | 'logXhrError'> & {
  logXhrError: (error: Object) => void;
  logError: (error: Error) => void;
};

class WrappedEventLogger implements EventLogger {
  uiEventLogger: UIEventLogger;
  appInfo: { appName: string; appVersion?: string | null };
  clientMeta: Object;

  constructor(uiEventLogger: UIEventLogger, clientMeta: Object) {
    this.uiEventLogger = uiEventLogger;
    this.appInfo = getAppInfo();
    this.clientMeta = clientMeta || {};
  }

  // eslint-disable-next-line zenefits-custom-rules/no-accessors
  get anonymousId() {
    return this.uiEventLogger.anonymousId;
  }

  // eslint-disable-next-line zenefits-custom-rules/no-accessors
  get transitionId() {
    return this.uiEventLogger.transitionId;
  }

  // eslint-disable-next-line zenefits-custom-rules/no-accessors
  set transitionId(transitionId: string | null) {
    this.uiEventLogger.transitionId = transitionId;
  }

  addLogFilter(filter: LogFilter) {
    this.uiEventLogger.addLogFilter(filter);
  }

  setTransitionInfo(route: string) {
    this.uiEventLogger.setTransitionInfo(route);
  }

  /**
   * @deprecated - Please use log() instead
   */
  logWithoutSchemaValidation(eventName: string, eventData: Object = {}) {
    this.uiEventLogger.log(eventName, {
      ...eventData,
      fromReact: true,
      appInfo: this.appInfo,
      clientMeta: this.clientMeta,
    });
  }

  log<T extends string | 'pageview'>(
    eventName: T,
    eventData?: T extends 'pageview' ? Object : FrontendAll['properties'],
  ) {
    this.uiEventLogger.log(eventName, {
      ...eventData,
      fromReact: true,
      appInfo: this.appInfo,
      clientMeta: this.clientMeta,
    });
  }

  addEventProperties(logId: string, properties: any) {
    this.uiEventLogger.addEventProperties(logId, properties);
  }

  scheduleLog(eventName: string, data?: Object, eventCategory?: string | null, logId?: string, timeout?: number) {
    this.uiEventLogger.scheduleLog(
      eventName,
      {
        ...data,
        fromReact: true,
        appInfo: this.appInfo,
        clientMeta: this.clientMeta,
      },
      eventCategory,
      logId,
      timeout,
    );
  }

  /**
   * @deprecated - Please use batchLog() instead
   */
  batchLogWithoutSchemaValidation(eventName: string, eventData: Object = {}) {
    this.uiEventLogger.batchLog(eventName, {
      ...eventData,
      fromReact: true,
      appInfo: this.appInfo,
      clientMeta: this.clientMeta,
    });
  }

  batchLog<T extends string | 'pageview'>(
    eventName: T,
    data?: T extends 'pageview' ? Object : FrontendAll['properties'],
  ) {
    this.uiEventLogger.batchLog(eventName, {
      ...data,
      fromReact: true,
      appInfo: this.appInfo,
      clientMeta: this.clientMeta,
    });
  }

  addClientMeta(clientMeta: Object) {
    this.clientMeta = {
      ...this.clientMeta,
      ...clientMeta,
    };
  }

  logError(error: Error) {
    this.uiEventLogger.logError(error, {
      appInfo: this.appInfo,
      clientMeta: this.clientMeta,
    });
  }

  logXhrError(error: Object) {
    this.uiEventLogger.logXhrError(error, {
      appInfo: this.appInfo,
      clientMeta: this.clientMeta,
    });
  }

  setCurrentUserData(params: { [key: string]: any }) {
    this.uiEventLogger.setCurrentUserData(params);
  }

  setPrivateData(params: { [key: string]: any }) {
    this.uiEventLogger.setPrivateData(params);
  }
}

let eventLogger: WrappedEventLogger;

export const getEventLogger = () => eventLogger;

export type LogFilter = (eventName: string, eventData?: any, eventCategory?: string) => boolean;

// Don't log pageViews from ember if a full page embedded react app is active
export function embeddedAppLogFilter(eventName: string, eventData: any) {
  if (eventName === 'pageview') {
    const embeddedAppActive =
      window.embeddedReactApps && Object.values(window.embeddedReactApps).some((app: any) => app.fullPageActive);
    if (embeddedAppActive) {
      return embeddedAppActive && eventData.fromReact;
    }
  }
  return true;
}

// For boot app don't log pageviews unless it's from an embedded app
export function embeddedAppOnlyLogFilter(eventName: string, eventData: any) {
  if (eventName === 'pageview') {
    const embeddedAppActive =
      window.embeddedReactApps && Object.values(window.embeddedReactApps).some((app: any) => app.fullPageActive);
    if (!embeddedAppActive) {
      return !eventData.fromReact;
    }
  }
  return true;
}

export const createEventLogger = (clientMeta: any = {}) => {
  let loggerEnvironment = 'production';
  if (__ENABLE_UILOGGER_LOCALLY__) {
    // In order to test the ui-logger-service locally
    loggerEnvironment = 'test';
  } else if (__DEVELOPMENT__) {
    loggerEnvironment = 'development';
  }

  const uiEventLogger = new UIEventLogger(getAppInfo(), loggerEnvironment, ajaxAdapter, clientMeta, {
    debounce,
    logFilters: [embeddedAppLogFilter],
  });

  eventLogger = new WrappedEventLogger(uiEventLogger, clientMeta);
  return eventLogger;
};

// creating event logger here for event we log before calling setCurrentUserData
eventLogger = createEventLogger({});

let enteredFirstTransition = false;
let flushedGqlTimingQueue = false;

// Wraps eventLogger.setTransitionInfo and lets us keep track of when we've entered our first transition.
// Due to boomerang potentially loading after gql calls we want to wait until boomerang has loaded and out transitionId has been set to log these calls
export function setTransitionInfo(currentRoute: string) {
  enteredFirstTransition = true;
  eventLogger.setTransitionInfo(currentRoute);
  flushGqlTiming();
}

// Keep track of gqlTiming events until eventLogger is initialized and we have a transitionId
let initialGqlTimingQueue: GQLTimingArgs[] = [];

type GQLTimingArgs = { queries: string[]; duration: number; dataSize?: number };

export function flushGqlTiming() {
  initialGqlTimingQueue.forEach(item => {
    eventLogger.batchLogWithoutSchemaValidation(GQL_TIMING_EVENT, {
      ...commonTimingProps,
      queries: item.queries,
      duration: item.duration,
      dataSize: item.dataSize,
      transitionId: eventLogger.transitionId,
    });
  });
  initialGqlTimingQueue = [];
  flushedGqlTimingQueue = true;
}

export function logGqlTiming(args: GQLTimingArgs) {
  if (!flushedGqlTimingQueue && !eventLogger.transitionId && !enteredFirstTransition) {
    initialGqlTimingQueue.push(args);
    return;
  }
  eventLogger.batchLogWithoutSchemaValidation(GQL_TIMING_EVENT, {
    ...commonTimingProps,
    queries: args.queries,
    duration: args.duration,
    dataSize: args.dataSize,
    transitionId: eventLogger.transitionId,
  });
}
