import constants from './constants';

const version = '$VERSION';
const EVENT_LOGGER_MAX_NUM_CHARACTERS = 65535;

/**
 * Copy all properties from one object to another, in a shallow fashion.
 *
 * @param  {Object} to   Destination object
 * @param  {Object} from Source object
 * @return {Object}      Destination object
 * @static
 */
const shallowCopy = function(to, from) {
  from = from || {};
  for (let p in from) {
    to[p] = from[p];
  }
  return to;
};

/**
 * Return the `toJSON` of an input, if possible, otherwise iterate
 * its keys and return non-function values
 *
 * @param  {*} Input to attempt to return `toJSON` for
 * @return {Object} JSON.stringifiable object
 * @static
 */
const safeToJSON = function(obj) {
  let jsonized;
  let item;
  if (obj && typeof obj.toJSON == 'function') {
    return obj.toJSON();
  }
  jsonized = {};
  for (let p in obj) {
    item = obj[p];
    // Functions aren't serializable
    if (typeof item == 'function') {
      continue;
    }
    // Non-null objects
    if (typeof item == 'object' && item) {
      jsonized[p] = safeToJSON(item);
    }
    // Scalars, null
    else {
      jsonized[p] = item;
    }
  }
  return jsonized;
};

const globalizeSpecials = function() {
  // FIXME: Export all the special keys as globals for backward compat
  // Remove this code as soon as possible, when references are updated
  // to point to the zen namespace obj
  // Match keys like zen._TransactionSavingController, zen._ApplicationRoute, zen._MultiModelMixin
  let pat = /^_[A-Z][A-Za-z0-9]+(:?Mixin|Controller|Route|Utils|Model)/;
  let p;
  for (p in window.zen) {
    if (pat.test(p)) {
      window[p] = window.zen[p];
    }
  }
  for (p in constants) {
    window[p] = constants[p];
  }
};

export default {
  version,
  EVENT_LOGGER_MAX_NUM_CHARACTERS,
  shallowCopy,
  safeToJSON,
  globalizeSpecials,
};
