import core from './core';

export { default as SessionTimer } from './session-timer';
export { default as constants } from './constants';
export { default as cookie } from './cookie';
export { default as uiEventLogger } from './ui-event-logger';

const { version, shallowCopy, safeToJSON, globalizeSpecials, EVENT_LOGGER_MAX_NUM_CHARACTERS } = core;
export { version, shallowCopy, safeToJSON, globalizeSpecials, EVENT_LOGGER_MAX_NUM_CHARACTERS };
globalizeSpecials();
