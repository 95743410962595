import { ApolloClient } from 'apollo-client';
import { BatchHttpLink } from 'apollo-link-batch-http';
import { HttpLink } from 'apollo-link-http';
import { onError, ErrorResponse } from 'apollo-link-error';
import { ApolloReducerConfig, IntrospectionFragmentMatcher, InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloProvider } from 'react-apollo';
import { ApolloLink, Operation } from 'apollo-link';

import 'z-frontend-global-types';

import { MockConfig } from './apolloTypes';
import { logGqlTiming } from './event-logger';
import getDefaultHeaders from './utils/get-default-headers';
import { setApolloClient } from './getApollo';
import apolloLinkContext from './apolloLinkContext';
import { getEventLogger } from '..';
import sessionTimer from './session-timer';

type GetAdditionalHeaders = () => Promise<{ [key: string]: string }>;
type OnGraphqlError = (error: ErrorResponse) => void;
export { ErrorResponse };

export interface NetworkInterfaceInitParamsFetch {
  fetch: (input: RequestInfo, init?: RequestInit) => Promise<Response>;
}

const MAX_CHARS_FOR_PERFORMANCE_LOGGING_PARSING = 8000;

let performanceLoggingDisabled = false;
let clientRedirectToLoginOnAuthError = true;

interface NetworkInterfaceInitParams {
  url?: string;
  getAdditionalHeaders?: GetAdditionalHeaders;
  onGraphqlError?: OnGraphqlError;
  disableBatching?: boolean;
}

export function disablePerformanceLogging() {
  performanceLoggingDisabled = true;
}

export function setRedirectToLoginOnAuthError(redirectOnError: boolean) {
  clientRedirectToLoginOnAuthError = redirectOnError;
}

export function getQueriesFromFetchCall(options: RequestInit) {
  if (typeof options.body === 'string') {
    const body = JSON.parse(options.body);
    if (Array.isArray(body)) {
      return body.map(batchedRequest => batchedRequest.operationName);
    }
    return [body.operationName];
  }
  return [];
}

function initializeNetworkInterface(params: NetworkInterfaceInitParams & NetworkInterfaceInitParamsFetch) {
  let graphqlUrl = '/graphql/';
  if (params.url) {
    graphqlUrl = params.url;
  } else if (__NATIVE__) {
    if (__ANDROID__) {
      // this is default IP address for localhost in genymotion emulator
      graphqlUrl = 'http://10.0.3.2:3000/graphql';
    } else if (__IOS__) {
      graphqlUrl = 'http://localhost:3000/graphql';
    }
  }

  const commonHttpLinkParams: HttpLink.Options = {
    uri: graphqlUrl,
    credentials: 'include',
    fetch: async (uri: string, options: RequestInit) => {
      (options.headers as any).accept = 'application/json';

      // add current origin value as 'x-origin' header so graphql server can use it as base url
      // if browser doesn't add 'origin' header (e.g IE11 in some cases)
      if (window && window.location) {
        (options.headers as any)['x-origin'] = window.location.origin;
      }

      const defaultHeaders = getDefaultHeaders();
      Object.assign(options.headers, defaultHeaders);

      if (typeof params.getAdditionalHeaders === 'function') {
        const additonalHeaders = await params.getAdditionalHeaders();
        Object.assign(options.headers, additonalHeaders);
      }

      const requestStart = new Date();
      return params.fetch(uri, options).then(res => {
        try {
          const duration = new Date().getTime() - requestStart.getTime();
          // Don't parse large payloads to avoid per regression
          if (!performanceLoggingDisabled) {
            const dataSize = typeof options.body === 'string' ? options.body.length : undefined;
            if (dataSize && dataSize > MAX_CHARS_FOR_PERFORMANCE_LOGGING_PARSING) {
              logGqlTiming({ duration, dataSize, queries: ['NOT_PARSED_LARGE_PAYLOAD'] });
            } else {
              logGqlTiming({
                duration,
                dataSize,
                queries: getQueriesFromFetchCall(options),
              });
            }
          }
          return res;
        } catch (err) {
          getEventLogger().logError(err);
          return res;
        }
      });
    },
  };

  const batchLink = new BatchHttpLink({
    ...commonHttpLinkParams,
    batchKey: (operation: Operation) => {
      // Where limited batching is on only batch queries if they have the same operation name
      if (operation.getContext().limitedBatching || params.disableBatching) {
        return operation.operationName;
      }
      if (operation.getContext().disabledBatching) {
        return operation.operationName;
      }

      return operation.getContext().headers && operation.getContext().headers['IS-BACKGROUND-QUERY']
        ? 'background-query'
        : 'normal-query';
    },
    batchMax: window.Cypress ? 1 : 10, // Cypress don't batch since it means that requests are sent on a timeout and may not be sent in time
  });

  const httpLink = new HttpLink(commonHttpLinkParams);

  const errorLink = onError(errorResponse => {
    const { networkError, graphQLErrors } = errorResponse;

    if (networkError && (networkError as any).statusCode >= 400) {
      if (__DEVELOPMENT__) {
        console.error('Got a network error from GraphQL server', networkError);
      }
    } else if (graphQLErrors && graphQLErrors.length) {
      if (__DEVELOPMENT__) {
        console.error('Got a graphql error(s) from the server', graphQLErrors);
      }
      graphQLErrors.forEach(gqlError => {
        // maybe log these errors?
        if ((gqlError as any).isNetworkError && (gqlError as any).status === 401 && clientRedirectToLoginOnAuthError) {
          // TODO: check if hash part is not being dropped after going back to the app
          if (!__NATIVE__) {
            sessionTimer.redirectToLogin();
          }
        }
      });
    }

    if (typeof params.onGraphqlError === 'function') {
      params.onGraphqlError(errorResponse);
    }
  });
  return ApolloLink.from([
    errorLink,
    // @ts-ignore strange inconsistency between httpLink and batchLink - apollo upgrade should fix
    apolloLinkContext.split(operation => operation.getContext().disableBatching, httpLink, batchLink),
  ]);
}

interface ApolloClientCreatorProps {
  client: ApolloClient<any>;
}

export interface ApolloClientOptions extends NetworkInterfaceInitParams {
  mockConfig?: MockConfig;
  fragmentTypes?: any;
  disablePerformanceLogging?: boolean;
  disableBatching?: boolean;
  assumeImmutableResults?: boolean;
  redirectToLoginOnAuthError?: boolean;
}

export default function createApolloClient(
  apolloClientOptions: ApolloClientOptions & NetworkInterfaceInitParamsFetch,
): ApolloClient<any> {
  const {
    mockConfig,
    getAdditionalHeaders,
    onGraphqlError,
    fragmentTypes,
    fetch,
    assumeImmutableResults,
    redirectToLoginOnAuthError = true,
    ...rest
  } = apolloClientOptions;

  clientRedirectToLoginOnAuthError = redirectToLoginOnAuthError;
  let link: ApolloLink | null = null;
  if ((__MOCK_MODE__ || __IS_STORYBOOK__) && mockConfig) {
    // The require is here so that it will only be pulled into the bundle if in mock or storybook mode
    const initializeMockedNetworkInterface = require('./initializeMockedNetworkInterface').default;
    link = initializeMockedNetworkInterface(mockConfig);
  } else {
    link = initializeNetworkInterface({
      getAdditionalHeaders,
      onGraphqlError,
      fetch,
      ...rest,
    });
  }

  const cacheConfig: ApolloReducerConfig = {
    addTypename: true,
  };

  if (fragmentTypes) {
    cacheConfig.fragmentMatcher = new IntrospectionFragmentMatcher({
      introspectionQueryResultData: fragmentTypes,
    });
  }

  const client = new ApolloClient({
    link,
    assumeImmutableResults,
    cache: new InMemoryCache({ ...cacheConfig, freezeResults: assumeImmutableResults }),
  } as any);

  setApolloClient(client);
  return client;
}

export function getApolloProvider(client: ApolloClient<any>): [typeof ApolloProvider, ApolloClientCreatorProps] {
  const apolloProviderProps: ApolloClientCreatorProps = { client };

  return [ApolloProvider, apolloProviderProps];
}
