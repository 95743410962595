import core from './core';
import isBrowser from './checkIfBrowser';

// Source: https://github.com/mde/fleegix-js-javascript-toolkit/blob/master/src/cookie.js
function Cookie() {
  this.set = function(name, value, optsParam) {
    if (!isBrowser) {
      return;
    }
    let opts;
    let dt;
    let expires;
    let parts = [`${name}=${value}`];

    if (optsParam) {
      if (typeof optsParam == 'object') {
        // Avoid polluting passed-in obj
        opts = core.shallowCopy({}, optsParam);
      }
      // Old API; last param is expiryDays
      else {
        dt = new Date();
        dt.setTime(dt.getTime() + optsParam * 24 * 60 * 60 * 1000);
        opts = {
          expires: dt,
        };
      }
    } else {
      opts = {};
    }

    // Default path
    if (!opts.path) {
      opts.path = '/';
    }

    // 'expires' has been set, convert Dates to string if needed
    // eslint-disable-next-line prefer-destructuring
    if ((expires = opts.expires)) {
      if (typeof expires.toGMTString == 'function') {
        opts.expires = expires.toGMTString();
      }
    }

    for (let p in opts) {
      parts.push(`${p}=${opts[p]}`);
    }

    document.cookie = parts.join('; ');
  };

  this.get = function(name) {
    if (!isBrowser) {
      return;
    }
    let nameEq = `${name}=`;
    let arr = document.cookie.split(/;\s*/);
    let c;
    for (let i = 0; i < arr.length; i++) {
      c = arr[i];
      if (c.indexOf(nameEq) === 0) {
        return c.substring(nameEq.length, c.length);
      }
    }
  };

  this.create = this.set;

  this.unset = function(name, optsParam) {
    let opts = core.shallowCopy({}, optsParam);
    let dt = new Date(1970, 0, 1);
    opts.expires = dt;
    this.set(name, '', opts);
  };

  this.destroy = this.unset;
}

const cookie = new Cookie();

export default cookie;
