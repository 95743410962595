const getEventEmitter = () => {
  const queuedEvents: string[] = [];
  const events: { [key: string]: Function[] } = {};

  const on = (eventName: string, callbackFn: Function) =>
    (events[eventName] = [...(events[eventName] || []), callbackFn]);
  const trigger = (eventName: string) =>
    window.ZENEFITS_MOBILE_INTEGRATION.isNativeReady
      ? events[eventName]?.forEach(cb => cb())
      : queuedEvents.push(eventName);
  const flushQueue = () => {
    queuedEvents.forEach(trigger);
    queuedEvents.length = 0;
  };
  const setIsNativeReady = () => {
    window.ZENEFITS_MOBILE_INTEGRATION.isNativeReady = true;
    flushQueue();
  };
  return { on, trigger, setIsNativeReady };
};

export default function initMobileIntegration() {
  if (window.ZENEFITS_MOBILE_INTEGRATION?.on) return; // already initialized, return early

  window.ZENEFITS_MOBILE_INTEGRATION = { ...(window.ZENEFITS_MOBILE_INTEGRATION || {}), ...getEventEmitter() };
}
